// Raleway font

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-Black.eot');
	src: local('Raleway Black'), local('Raleway-Black'),
		url('../../fonts/Raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-Black.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-Medium.eot');
	src: local('Raleway Medium'), local('Raleway-Medium'),
		url('../../fonts/Raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-Medium.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-Regular.eot');
	src: local('Raleway'), local('Raleway-Regular'),
		url('../../fonts/Raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-Regular.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-Light.eot');
	src: local('Raleway Light'), local('Raleway-Light'),
		url('../../fonts/Raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-Light.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-Thin.eot');
	src: local('Raleway Thin'), local('Raleway-Thin'),
		url('../../fonts/Raleway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-Thin.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-SemiBold.eot');
	src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
		url('../../fonts/Raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: Raleway;
	font-display: swap;
	src: url('../../fonts/Raleway/Raleway-Bold.eot');
	src: local('Raleway Bold'), local('Raleway-Bold'),
		url('../../fonts/Raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Raleway/Raleway-Bold.woff') format('woff'),
		url('../../fonts/Raleway/Raleway-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

// Montserrat font

@font-face {
	font-family: Montserrat;
	font-display: swap;
	src: url('../../fonts/Montserrat/Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url('../../fonts/Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Montserrat/Montserrat-Thin.woff') format('woff'),
		url('../../fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: Montserrat;
	font-display: swap;
	src: url('../../fonts/Montserrat/Montserrat-Light.eot');
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('../../fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('../../fonts/Montserrat/Montserrat-Light.woff') format('woff'),
		url('../../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
