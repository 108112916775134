$customers: 140px;

.customers {
  position: relative;
  padding-top: $customers;
  padding-bottom: 0;
  .mark-block {
    max-width: 480px;
  }
  .col-sm-2 {
    margin-bottom: -50px;
    @media #{$xl} {
      margin-bottom: -30px;
    }
    @media #{$sm} {
      margin-bottom: 0;
    }
    &:last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .mark-block {
    top: -$customers;
    display: flex;
    align-items: center;
    margin-top: -$customers;
    img {
      flex-shrink: 0;
    }
    .text {
      padding-left: 20px;
    }
  }
}

.customers-map {
  z-index: -1;
  position: relative;
  top: -$customers;
  right: -5%;
  width: 1221px;
  height: 601px;
  background: url('/img/customers/customers-map.png') no-repeat center / cover;
  background-position-y: -50px;
  @media #{$xl} {
    width: 950px;
    height: 468px;
    background-position-y: -40px;
  }
  @media #{$lg} {
    right: -27%;
  }
  @media (max-width: 1023px) {
    right: -120px;
    width: 850px;
    height: 418px;
  }
  @media #{$sm} {
    right: -330px;
    background-position-y: 0;
  }
}

@media #{$xl} {
  $customers: 70px;
  .customers {
    padding-top: $customers;
  }
  .customers-map {
    top: -$customers;
  }
  .customers .mark-block {
    top: -100px;
    margin-top: -$customers;
  }
}

@media #{$sm} {
  $customers: 0;
  .customers {
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .customers-map {
    top: $customers;
  }
  .customers .mark-block {
    top: $customers;
    margin-top: $customers;
  }
}

.customer-location {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: $color-second;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba($color-second, 0.4);
  animation-name: pulse;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  @media #{$xl} {
    width: 4px;
    height: 4px;
  }
  @media #{$sm} {
    width: 3px;
    height: 3px;
  }
  &--1 {
    top: 22%;
    left: 20.5%;
    animation-delay: 1s;
    @media #{$sm} {
      top: 30%;
    }
  }
  &--2 {
    top: 34%;
    left: 22%;
    animation-delay: 3s;
    @media #{$sm} {
      top: 42%;
    }
  }
  &--3 {
    bottom: 35%;
    left: 33%;
    animation-delay: 2s;
    @media #{$sm} {
      bottom: 27%;
    }
  }
  &--4 {
    bottom: 22%;
    left: 28%;
    animation-delay: 0s;
    @media #{$sm} {
      bottom: 14%;
    }
  }
  &--5 {
    bottom: 27.5%;
    left: 53.5%;
    animation-delay: 2s;
    @media #{$sm} {
      bottom: 19.5%;
    }
  }
  &--6 {
    bottom: 26%;
    right: 12%;
    animation-delay: 1s;
    @media #{$sm} {
      bottom: 18%;
    }
  }
  &--7 {
    top: 35.5%;
    right: 15.5%;
    animation-delay: 0s;
    @media #{$sm} {
      top: 43.5%;
    }
  }
  &--8 {
    top: 37%;
    right: 24%;
    animation-delay: 3s;
    @media #{$sm} {
      top: 45%;
    }
  }
  &--9 {
    top: 42%;
    right: 38%;
    animation-delay: 1s;
    @media #{$sm} {
      top: 50%;
    }
  }
  &--10 {
    top: 38.5%;
    right: 42.5%;
    animation-delay: 2s;
    @media #{$sm} {
      top: 46.5%;
    }
  }
  &--11 {
    top: 29.5%;
    left: 50%;
    animation-delay: 0s;
    @media #{$sm} {
      top: 37.5%;
    }
  }
  &--12 {
    top: 32%;
    left: 45.5%;
    animation-delay: 3s;
    @media #{$sm} {
      top: 40%;
    }
  }
  &--13 {
    top: 26.5%;
    left: 47%;
    animation-delay: 1s;
    @media #{$sm} {
      top: 34.5%;
    }
  }
  &--14 {
    top: 23.5%;
    left: 48%;
    animation-delay: 3s;
    @media #{$sm} {
      top: 31.5%;
    }
  }
  &--15 {
    top: 22.8%;
    left: 46.3%;
    animation-delay: 0s;
    @media #{$sm} {
      top: 30.5%;
    }
  }
  &--16 {
    top: 22%;
    left: 45%;
    animation-delay: 2s;
    @media #{$sm} {
      top: 30%;
    }
  }
  &--17 {
    width: 29px !important;
    height: 38px !important;
    border-radius: 0;
    background: transparent;
    top: 16%;
    left: 56%;
    animation: none;
    @media #{$sm} {
      top: 24%;
    }
  }
}

.ukr-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  height: 38px;
  background: url('/img/customers/customers-ic-1.svg') no-repeat center /cover;
}

.ukr-location {
  position: absolute;
  //top: 100%;
  bottom: -25px;
  left: 50%;
  //margin-top: -25px;
  //margin-left: -25px;
  transform: rotateX(55deg) translateX(-50%);
  will-change: transform;
  &::before,
  &::after {
    content: '';
    //animation: pulsate 1.5s ease-out;
    //animation-delay: 0s;
    //animation-iteration-count: infinite;
    opacity: 0;
    border-radius: 50%;
    border: 1px dashed $color-second;
    will-change: transform, opacity;
  }
  &::before {
    display: block;
    width: 50px;
    height: 50px;
  }
  &::after {
    position: absolute;
    top: -10px;
    left: -10px;
    display: block;
    width: 70px;
    height: 70px;
  }
}
