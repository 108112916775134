.foryou {
  // .title {
  //   margin-bottom: 70px;
  //   @media #{$xl} {
  //     margin-bottom: 50px;
  //   }
  //   @media #{$md} {
  //     margin-bottom: 30px;
  //   }
  // }
  .row {
    position: relative;
    justify-content: space-between;
    &::after {
      position: absolute;
      content: 'vs';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      font-size: 100px;
      font-weight: 100;
      @media #{$xl} {
        font-size: 50px;
      }
      @media #{$md} {
        display: none;
      }
    }
  }
  .col-md-2 {
    &:first-of-type {
      @media #{$md} {
        margin-bottom: 50px;
      }
      @media #{$sm} {
        margin-bottom: 30px;
      }
      .foryou__item-img {
        background-image: url('/img/foryou/foryou-img-1.jpg');
      }
      .foryou__item-content {
        padding-left: 70px;
        padding-right: 155px;
        @media #{$xl} {
          padding-left: 30px;
          padding-right: 55px;
        }
        @media #{$sm} {
          padding-left: 0;
        }
      }
    }
    &:last-of-type {
      @media #{$sm} {
        margin-bottom: 0;
      }
      .foryou__item {
        @media #{$sm} {
          flex-direction: column-reverse;
        }
      }
      .foryou__item-img {
        background-image: url('/img/foryou/foryou-img-2.jpg');
      }
      .foryou__item-content {
        padding-left: 155px;
        padding-right: 70px;
        @media #{$xl} {
          padding-left: 55px;
          padding-right: 30px;
        }
        @media #{$md} {
          text-align: right;
        }
        @media #{$sm} {
          text-align: left;
          padding-left: 0;
          padding-right: 55px;
        }
      }
      .foryou__list-item {
        @media #{$md} {
          padding-left: 0;
          padding-right: 35px;
          &::before {
            left: auto;
            right: 0;
          }
        }
        @media #{$sm} {
          padding-left: 35px;
          padding-right: 0;
          &::before {
            right: auto;
            left: 0;
          }
        }
      }
    }
  }
}

.foryou__item {
  display: flex;
  @media #{$sm} {
    flex-direction: column;
  }
}

.foryou__item-img {
  flex: 0 0 38%;
  max-width: 38%;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
  @media #{$md} {
    min-height: 300px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media #{$sm} {
    min-height: 150px;
    flex: 0 0 auto;
    max-width: 100%;
    background-position-y: 17%;
    margin-bottom: 30px;
  }
}

.foryou__item-content {
  flex: 0 0 62%;
  max-width: 62%;
  @media #{$md} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media #{$sm} {
    flex: 0 0 auto;
    max-width: 100%;
  }
}

.foryou__item-title {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media #{$xl} {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media #{$md} {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.foryou__list-item {
  position: relative;
  line-height: 1.5;
  padding-left: 45px;
  &::before {
    position: absolute;
    content: '';
    top: 3px;
    left: 0;
    width: 30px;
    height: 30px;
    display: block;
    background-color: #f2f2f2;
    border-radius: 2px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    @media #{$xl} {
      width: 20px;
      height: 20px;
    }
  }
  @media #{$xl} {
    font-size: 16px;
    padding-left: 35px;
  }
  @media #{$md} {
    font-size: 14px;
  }
  + .foryou__list-item {
    margin-top: 30px;
    @media #{$xl} {
      margin-top: 20px;
    }
    @media #{$sm} {
      margin-top: 15px;
    }
  }
  &.lightning {
    &::before {
      background-image: url('/img/foryou/foryou-ic-1.svg');
    }
  }
  &.pazl {
    &::before {
      background-image: url('/img/foryou/foryou-ic-2.svg');
    }
  }
  &.football {
    &::before {
      background-image: url('/img/foryou/foryou-ic-3.svg');
    }
  }
  &.apple {
    &::before {
      background-image: url('/img/foryou/foryou-ic-4.svg');
    }
  }
  &.ball {
    &::before {
      background-image: url('/img/foryou/foryou-ic-5.svg');
    }
  }
}
