.wrapper {
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: $container;
  margin: 0 auto;
}

.title {
  font-size: 80px;
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media #{$xl} {
    font-size: 60px;
    margin-bottom: 30px;
  }
  @media #{$md} {
    font-size: 40px;
  }
  @media #{$sm} {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1;
  }
}

.title-thin {
  display: block;
  font-size: 50px;
  font-weight: 100;
  letter-spacing: 0.025em;
  line-height: 0.97;
  @media #{$xl} {
    font-size: 35px;
  }
  @media #{$md} {
    font-size: 25px;
  }
  @media #{$sm} {
    font-size: 18px;
  }
}

.sub-title {
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 25px;
  @media #{$xl} {
    font-size: 18px;
    margin-bottom: 15px;
  }
  @media #{$md} {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.title-mark {
  display: block;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: $color-second;
  // padding-top: 20px;
  // padding-bottom: 12px;
  @media #{$xl} {
    font-size: 40px;
  }
  @media #{$md} {
    font-size: 30px;
  }
  @media #{$sm} {
    font-size: 23px;
  }
}

.text {
  font-size: 18px;
  line-height: 1.5;
  color: #424242;
  @media #{$xl} {
    font-size: 16px;
  }
  @media #{$md} {
    font-size: 14px;
  }
}

.text-normal {
  font-weight: 400;
}

.numbers {
  font-size: 80px;
  font-weight: 100;
  font-family: $font-second;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  @media #{$xl} {
    font-size: 60px;
  }
  @media #{$md} {
    font-size: 40px;
  }
}

.mark-block {
  position: relative;
  justify-content: center;
  color: $color-second;
  background-color: #fff4ee;
  padding: 20px;
  &::before {
    z-index: 1;
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 75%;
    display: block;
    background-color: $color-second;
  }
  @media #{$xl} {
    padding: 15px;
  }
  .text {
    color: $color-second;
  }
}

.list {
  margin-bottom: 20px;
}

.list.first-type {
  margin-bottom: 60px;
}

.list-item {
  position: relative;
  line-height: 2;
  padding-left: 40px;
  @media #{$xl} {
    padding-left: 25px;
  }
  @media #{$md} {
    padding-left: 20px;
  }
  &::before {
    position: absolute;
    content: '';
    top: 14px;
    left: 0;
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
    background-color: #b2b1b1;
    @media #{$xl} {
      width: 6px;
      height: 6px;
    }
    @media #{$md} {
      top: 12px;
      width: 5px;
      height: 5px;
    }
  }
  &--second {
    &::before {
      background-color: $color-second;
    }
  }
}

.btn {
  width: 100%;
  max-width: 360px;
  @include centerFlex;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  background-color: $color-second;
  border-radius: 50px;
  padding: 27px 15px;
  transition: background-color .3s;
  &:hover {
    background-color: darken($color-second, 5%);
  }
  @media #{$xl} {
    max-width: 285px;
    font-size: 17px;
    padding: 20px 15px;
  }
  @media #{$md} {
    max-width: 220px;
    font-size: 14px;
    padding: 15px;
  }
  @media #{$sm} {
    max-width: 180px;
    font-size: 12px;
    letter-spacing: 0.07em;
    padding: 14px;
  }

  &.btn--white {
    padding: 16px 20px;
    color: $color-main;
    font-weight: 600;
    font-size: 15px;
    font-family: $font-second;
    letter-spacing: 0.05em;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0;
  }
}
