@mixin col1 {
  flex: 0 0 auto;
  max-width: 100%;
}

@mixin col2 {
  flex: 0 0 50%;
  max-width: 50%;
}

@mixin col3 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

@mixin col4 {
  flex: 0 0 25%;
  max-width: 25%;
}

@mixin col5 {
  flex: 0 0 20%;
  max-width: 20%;
}

@mixin col6 {
  flex: 0 0 16.666666%;
  max-width: 16.666666%;
}

@mixin col7 {
  flex: 0 0 14.285714%;
  max-width: 14.285714%;
}

@mixin col8 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

@mixin centerFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@mixin slickNav {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 150px;
    width: 30px;
    height: 30px;
    border: 1px solid $color-main;
    font-size: 0;
    background: transparent;
    cursor: pointer;
    outline: 0;
    z-index: 1;
    border-bottom: 0;
    padding: 0;
  }
  .slick-prev {
    left: 0;
    border-right: 0;
    transform: translateY(-50%) rotate(-45deg) skew(-15deg, -15deg);
  }
  .slick-next {
    right: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg) skew(15deg, 15deg);
  }
  // .slick-dots {
  //   justify-content: center;
  //   margin-top: 20px;
  //   li {
  //     margin: 0 7px;
  //     button {
  //       width: 12px;
  //       height: 12px;
  //       border-radius: 50%;
  //       background: #f4f4f4;
  //       margin: 0;
  //     }
  //     &.slick-active {
  //       button {
  //         width: 12px;
  //         height: 12px;
  //         background: #ff6d3c;
  //       }
  //     }
  //   }
  // }
}

@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin relative($args...) {
  @include position($position: relative, $args...);
}

@mixin absolute($args...) {
  @include position($position: absolute, $args...);
}

@mixin fixed($args...) {
  @include position($position: fixed, $args...);
}

@mixin sticky($args...) {
  @include position($position: sticky, $args...);
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
