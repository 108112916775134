.about {
  .row {
    @media #{$md} {
      flex-direction: column-reverse;
    }
  }
  .col-md-2 {
    &:first-of-type {
      padding-right: 125px;
      @media #{$xl} {
        padding-right: 100px;
      }
      @media #{$md} {
        height: 400px;
        padding-right: 30px;
      }
      @media #{$sm} {
        height: 250px;
        padding-right: 10px;
      }
    }
    &:last-of-type {
      //padding-right: 15px;
      @media #{$md} {
        padding-right: 20%;
      }
      @media #{$sm} {
        padding-right: 0;
      }
    }
  }
  .text:not(:last-child) {
    margin-bottom: 50px;
    @media #{$md} {
      margin-bottom: 30px;
    }
    @media #{$sm} {
      margin-bottom: 20px;
    }
  }
}

.about__img-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.about-img {
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
}

.about__img-item {
  display: flex;
  &:first-of-type {
    height: 60%;
    margin-bottom: 30px;
    @media #{$xl} {
      margin-bottom: 15px;
    }
    @media #{$sm} {
      margin-bottom: 10px;
    }
    .about-img {
      &:first-of-type {
        flex: 0 0 60%;
        max-width: 60%;
        background-image: url('/img/about/about-img-1.jpg');
        margin-right: 30px;
        @media #{$xl} {
          margin-right: 15px;
        }
        @media #{$sm} {
          margin-right: 10px;
        }
      }
      &:last-of-type {
        flex: 0 0 40%;
        max-width: 40%;
        background-image: url('/img/about/about-img-2.jpg');
      }
    }
  }
  &:last-of-type {
    height: 40%;
    .about-img {
      &:first-of-type {
        flex: 0 0 40%;
        max-width: 40%;
        background-image: url('/img/about/about-img-3.jpg');
        margin-right: 30px;
        @media #{$xl} {
          margin-right: 15px;
        }
        @media #{$sm} {
          margin-right: 10px;
        }
      }
      &:last-of-type {
        flex: 0 0 60%;
        max-width: 60%;
        background-image: url('/img/about/about-img-4.jpg');
      }
    }
  }
}
