.reviews {

}

.reviews-slider {
  margin-top: 60px;
  @media #{$xl} {
    margin-top: 40px;
  }
  @media #{$sm} {
    margin-top: 30px;
  }
}

.reviews-item {
  padding-right: 60px;
  @media #{$sm} {
    padding-right: 0;
  }
}

.reviews-item__header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  @media #{$xl} {
    margin-bottom: 25px;
  }
  @media #{$sm} {
    margin-bottom: 15px;
  }
}

.reviews-item__avatar {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  @media #{$sm} {
    width: 55px;
    height: 55px;
  }
  img {
    max-width: 100%;
  }
}

.reviews-item__mark {
  position: absolute;
  left: 46px;
  bottom: -5px;
  width: 28px;
  height: 28px;
  font-size: 55px;
  color: #fff;
  border-radius: 50%;
  background-color: $color-second;
  @media #{$sm} {
    left: 40px;
    bottom: -2px;
    width: 20px;
    height: 20px;
    font-size: 36px;
  }
  span {
    position: relative;
    right: -6px;
    top: 2px;
    @media #{$sm} {
      right: -5px;
    }
  }
}

.reviews-item__info {
  font-size: 16px;
  padding-left: 30px;
  @media #{$sm} {
    font-size: 14px;
    padding-left: 15px;
  }
}

.reviews-item__info-name {
  font-weight: 900;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.reviews-item__info-desc {

}

.reviews-controls {
  display: flex;
  align-items: center;
  margin-top: 70px;
  @media #{$xl} {
    margin-top: 50px;
  }
  @media #{$sm} {
    margin-top: 30px;
  }
  .total-slides {
    opacity: 0.5;
    &::before {
      content: '/';
    }
  }
  .current-slide {
    &.one-slide {
      @media #{$sm-min} {
        display: none;
      }
    }
  }
  .numbers {
    font-size: 50px;
    color: $color-second;
    @media #{$sm} {
      font-size: 30px;
    }
  }
  @media #{$sm} {
    .current-slide:not(.one-slide) {
      display: none;
    }
  }
}

.reviews-prev,
.reviews-next {
  position: relative;
  width: 45px;
  height: 40px;
  flex-shrink: 0;
  @media #{$sm} {
    width: 30px;
    height: 30px;
  }
  &::before,
  &::after {
    position: absolute;
    content: '';
    display: block;
  }
  &::before {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: $color-second;
  }
  &::after {
    top: 8px;
    width: 25px;
    height: 25px;
    border-width: 2px;
    border-style: solid;
    border-color: $color-second;
    border-bottom: 0;
    @media #{$sm} {
      top: 6px;
      width: 18px;
      height: 18px;
    }
  }
}

.reviews-prev {
  margin-right: 40px;
  @media #{$sm} {
    margin-right: 20px;
  }
  &::after {
    left: 2px;
    transform: rotate(-45deg);
    border-right: 0;
  }
}

.reviews-next {
  margin-left: 40px;
  @media #{$sm} {
    margin-left: 20px;
  }
  &::after {
    right: 2px;
    transform: rotate(45deg);
    border-left: 0;
  }
}
