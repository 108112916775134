body {
	font-family: $font-main;
	font-size: 18px;
	font-weight: 300;
	color: $color-main;
}

$section-padding: 15px;

section,
.footer {
  padding-left: $section-padding;
  padding-right: $section-padding;
}

@media #{$xl} {
  $section-padding: 60px;
  section,
	.footer {
  	padding-left: $section-padding;
  	padding-right: $section-padding;
  }
}

@media #{$md} {
  $section-padding: 30px;
  section,
	.footer {
  	padding-left: $section-padding;
  	padding-right: $section-padding;
  }
}

@media #{$sm} {
  $section-padding: 15px;
  section,
	.footer {
  	padding-left: $section-padding;
  	padding-right: $section-padding;
  }
}

section {
	padding-top: 50px;
	padding-bottom: 50px;
	@media #{$xl} {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@media #{$sm} {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}
