.modal-event {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  padding-left: 15px;
  padding-right: 15px;
  transition: opacity .3s linear;

  &__body {
    position: relative;
    width: 100%;
    max-width: 506px;
    padding: 150px 75px 30px 100px;
    color: #fff;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background-color: $color-main;
    
    @media only screen and (max-width: 576px) {
      max-width: 290px;
      padding: 80px 20px 20px 20px;
    }
  }

  &__banner {
    position: absolute;
    top: -20px;
    right: -20px;
    max-width: 463px;

    img {
      max-width: 100%;
    }

    @media only screen and (max-width: 576px) {
      top: -10px;
      right: -10px;
      max-width: 245px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 22px;
    height: 22px;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 22px;
      height: 2px;
      display: block;
      background-color: #fff;
      content: '';
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__event-name {
    position: absolute;
    top: 32px;
    left: 18px;

    img {
      max-height: 100%;
    }

    @media only screen and (max-width: 576px) {
      top: 10px;
      left: 18px;
      height: 90px;
    }

    &::after {
      position: absolute;
      top: -50px;
      left: 5px;
      display: block;
      width: 2px;
      height: 35px;
      margin: auto;
      background-color: #ff6d3c;
      content: "";

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  &__title {
    margin-top: 32px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 34px;
  }

  &__discount {
    position: absolute;
    bottom: -32px;
    left: -2px;
    color: #fff;
    font-weight: 900;
    font-size: 60px;
    line-height: 75px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__text {
    font-size: 16px;
    line-height: 1.67;
  }

  &__copyright {
    position: relative;
    margin-top: 50px;
    color: #b2b6bf;
    font-size: 13px;
    line-height: 1.5;

    @media only screen and (max-width: 576px) {
      margin-top: 20px;
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -120px;
      display: block;
      width: 106px;
      height: 2px;
      margin: auto;
      background-color: #ff6d3c;
      content: "";

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  &__promo {
    position: relative;
    margin-top: 15px;
    padding: 13px 20px;
    text-align: center;
    border: 1px dashed #7c808a;

    &-code {
      max-width: 100%;
      color: #ff6d3c;
      font-weight: 600;
      font-size: 15px;
      font-family: $font-second;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      cursor: text;
    }

    &-copy {
      position: absolute;
      top: 0;
      right: 11px;
      bottom: 0;
      width: 24px;
      height: 24px;
      margin: auto;
      background: url("/img/copy.svg") no-repeat center;
      cursor: pointer;
    }
  }

  .btn.btn--white {
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
    padding: 16px 20px;
  }

  &__link {
    flex-direction: row;

    &-arrow {
      position: relative;
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-left: 10px;

      &::before,
      &::after {
        position: absolute;
        display: block;
        content: "";
      }

      &::before {
        top: 50%;
        width: 13px;
        height: 2px;
        background-color: $color-main;
        transform: translate(0, -50%);
      }

      &::after {
        top: calc(50% - 4px);
        right: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 3px;
        border-color: $color-main;
        border-style: solid;
        border-width: 0 2px 2px 0;
        transform: rotate(-45deg);
      }
    }
  }

  &.is-active {
    pointer-events: auto;
    opacity: 1;
  }
}

.modal-collapse {
  display: none;
  position: fixed;
  top: 192px;
  right: 0;
  bottom: auto;
  left: auto;
  z-index: 100;
  width: 63px;
  height: 173px;
  padding: 0;
  background-color: $color-main;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    top: 100px;
    width: 54px;
    height: 116px;
  }

  &.show {
    display: block;
  }

  .modal-event__event-name::after {
    display: none;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: none;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s linear;

  &.show {
    opacity: 0.5;
  }
}

body.modal-open {
  padding-right: 15px;
  overflow-y: hidden;
}
