.footer {
  padding-top: 50px;
  padding-bottom: 30px;
  @media #{$sm} {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .text {
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 15px;
    @media #{$xl} {
      font-size: 14px;
      margin-bottom: 5px;
    }
    @media #{$sm} {
      font-size: 16px;
      margin-bottom: 10px;
    }
    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color-main;
      transform: rotateY(90deg);
      transform-origin: left;
      transition: transform .3s;
    }
    &:hover {
      &::after {
        transform: rotateY(0);
      }
    }
    &.privacy {
      font-family: $font-second;
      color: #bababa;
      transition: transform .3s,
                  color .3s;
      &::after {
        transform: rotateY(0);
        background-color: #bababa;
      }
      &:hover {
        color: darken(#bababa, 20%);
        &::after {
          transform: rotateY(90deg);
        }
      }
    }
  }
  .first-col {
    @media #{$md} {
      flex: 0 0 42%;
      max-width: 42%;
    }
    @media #{$sm} {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 auto;
    }
  }
  .last-col {
    @media #{$md} {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    @media #{$sm} {
      align-items: center;
      padding-top: 5px;
    }
    .footer-text {
      display: block;
      margin-bottom: 5px;
    }
  }
  .col-md-6:not(.last-col) {
    @media #{$md} {
      flex: 0 0 18%;
      max-width: 18%;
    }
    @media #{$sm} {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 auto;
    }
  }
}

.footer__logo-link {
  display: block;
  margin-bottom: 20px;
}

.footer__logo-img {
  max-width: 180px;
  @media #{$xl} {
    max-width: 150px;
  }
}

.footer-text {
  font-family: $font-second;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.68;
  color: #000;
  @media #{$xl} {
    font-size: 14px;
  }
  @media #{$sm} {
    text-align: center;
  }
}

.social-block {
  margin-top: 25px;
  @media #{$md} {
    margin-top: 10px;
  }
}

.social-link {
  width: 25px;
  height: 25px;
  display: inline-block;
  transition: opacity .3s;
  + .social-link {
    margin-left: 5px;
    @media #{$sm} {
      margin-left: 10px;
    }
  }
  &:hover {
    opacity: 0.8;
  }
}
