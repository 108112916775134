.fly-box-block {
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 120px;
  @media #{$xl} {
    padding-top: 60px;
  }
  @media #{$lg} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.fly-box {
  position: relative;
  + .fly-box {
    @media #{$xl} {
      margin-left: 15px;
    }
    @media #{$lg} {
      margin-left: 0;
    }
  }
  &:nth-of-type(1) {
    top: 110px;
    transition: transform 1s ease;
    @media #{$lg} {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  &:nth-of-type(2) {
    top: 50px;
    transition: transform .4s ease;
    @media #{$lg} {
      margin-bottom: 15px;
    }
  }
  &:nth-of-type(3) {
    top: 120px;
    transition: transform .3s ease;
    @media #{$lg} {
      margin-right: 15px;
    }
  }
  &:nth-of-type(4) {
    transition: transform .8s ease;
  }
  img {
    max-width: 100%;
    border-radius: 5px;
    overflow: hidden;
    @media #{$lg} {
      width: 100%;
    }
  }
  @media #{$lg} {
    top: 0 !important;
    animation: none !important;
    transform: none !important;
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}
