.modal {
  z-index: 50;
  @include cover;
  @include centerFlex;
  opacity: 0;
  pointer-events: none;
  padding-left: 15px;
  padding-right: 15px;
  transition: opacity .3s linear;
  &.is-active {
    pointer-events: auto;
    opacity: 1;
  }

  .btn {
    margin-top: 40px;
    padding: 20px 20px;
    max-width: max-content;
  }
}

.modal-success {
  position: relative;
  width: 100%;
  max-width: 620px;
  @include centerFlex;
  background-color: #fff;
  border-radius: 5px;
  padding: 50px 15px 60px;
  margin: 0 auto;
  @media #{$sm} {
    padding: 30px 15px 40px;
  }
  img {
    max-width: 200px;
    margin: 0 auto 25px;
    @media #{$sm} {
      max-width: 150px;
    }
  }
  p {
    color: $color-main;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    @media #{$sm} {
      font-size: 14px;
    }
  }
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  $size: 22px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: $size;
  height: $size;
  display: block;
  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: $size;
    height: 2px;
    display: block;
    background-color: $color-main;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
