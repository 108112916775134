.job-desc {
  .title {
    font-size: 100px;
    letter-spacing: 0.025em;
    @media #{$xl} {
      font-size: 80px;
    }
    @media #{$md} {
      font-size: 60px;
    }
    @media #{$sm} {
      font-size: 40px;      
    }
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    @media #{$xl} {
      font-size: 14px;
    }
    &.first-type {
      margin-bottom: 60px;
      @media #{$sm} {
        margin-bottom: 30px;
      }
    }
  }
  .sub-title {
    letter-spacing: 0;
  }
  .list-item {
    padding-left: 25px;
    &:before {
      width: 5px;
      height: 5px;
    }
    @media #{$sm} {
      padding-left: 15px;
    }
  }
}

.job-desc__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 180px;
  @media #{$xl} {
    padding-top: 150px;
  }
  @media #{$sm} {
    padding-top: 70px;
  }
}

.job-desc__sub-title {
  display: flex;
  font-size: 34px;
  @media #{$xl} {
    font-size: 26px;
  }
  @media #{$sm} {
    font-size: 18px;
  }
}

.job-desc__level {
  position: relative;
  padding-right: 45px;
  margin-right: 30px;
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 50%;
    background-color: $color-second;
    @media #{$sm} {
      width: 10px;
      height: 10px;
    }
  }
  @media #{$sm} {
    padding-right: 25px;
    margin-right: 20px;
  }
}

.job-desc__location {

}

.job-desc__back {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 2.46;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #90a4ae;
  padding-left: 15px;
  &::before {
    position: absolute;
    content: '';
    top: 12px;
    left: 0;
    width: 7px;
    height: 7px;
    border: 1px solid #90a4ae;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(-45deg);
    @media #{$sm} {
      top: 8px;
      width: 5px;
      height: 5px;
    }
  }
  @media #{$sm} {
    font-size: 10px;
    padding-left: 10px;
  }
}
