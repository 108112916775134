// media queries (max-width)
$xl: '(max-width: 1600px)';
$lg: '(max-width: 1280px)';
$md: '(max-width: 1024px)';
$sm: '(max-width: 767px)';
$xs: '(max-width: 500px)';

// media queries (min-width)
$xl-min: '(min-width: 1601px)';
$lg-min: '(min-width: 1281px)';
$md-min: '(min-width: 1025px)';
$sm-min: '(min-width: 768px)';
$xs-min: '(min-width: 501px)';

$ipad-landscape: '(width: 1366px) and (height: 1024px)';
$mobile-landscape: '(max-width: 812px) and (max-height: 414px)';
$mobile-xs-landscape: '(max-width: 568px) and (max-height: 320px)';
$iphoneX-landscape: '(width: 812px) and (height: 375px)';

// fonts
$font-main: 'Raleway', sans-serif;
$font-second: 'Montserrat', sans-serif;

// colors
$color-main: #3c424f; // black
$color-second: #ff5510; // red

// body
$container: 1530px;
