// keyframes animation
@keyframes homeDot {
  0% {
    background-color: #fc0119;
  }
  20% {
    background-color: #fed82f;
  }
  40% {
    background-color: $color-second;
  }
  60% {
    background-color: #32f4b1;
  }
  80% {
    background-color: #4c1997;
  }
  100% {
    background-color: #6e22e3;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($color-second, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($color-second, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($color-second, 0);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(.1, .1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

//custom aos animation
[data-aos='rain'] {
  opacity: 0;
  transform: translateY(-20em);
  transition: transform 1s ease-in;
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-aos='location'] {
  &.aos-animate {
    &::before,
    &::after {
      animation: pulsate 1.5s ease-out 1s infinite;
    }
  }
}
