.team {
  @include slickNav;
}

.team__item {
  max-width: 290px;
  margin-bottom: 50px;
  @media #{$xl} {
    margin-bottom: 40px;
  }
  @media #{$md} {
    margin-bottom: 30px;
  }
  @media #{$sm} {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.team__item-img {
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  img {
    max-width: 100%;
    @media #{$sm} {
      margin: 0 auto;
    }
    &.hover {
      display: none;
    }
  }
  &:hover {
    img:not(.hover) {
      display: none;
    }
    img.hover {
      display: block;
    }
  }
  &--you {
    height: 310px;
    @include centerFlex;
    color: $color-second;
    background-color: #fff4ee;
    img {
      cursor: pointer;
      margin-bottom: 25px;
      @media #{$sm} {
        margin: 0 auto 25px;
      }
    }
  }
}

.team__item-mark {
  font-size: 24px;
  font-weight: 900;
  line-height: 1.25;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  text-align: center;
  @media #{$md} {
    font-size: 20px;
  }
  @media #{$sm} {
    font-size: 24px;
  }
}

.team__item-header {
  margin-bottom: 20px;
  @media #{$sm} {
    margin-bottom: 15px;
  }
}

.team__item-desc {
  .text {
    font-size: 16px;
    @media #{$xl} {
      font-size: 14px;
    }
  }
}

.team__item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media #{$sm} {
    margin-bottom: 5px;
  }
}

.team__item-name {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  @media #{$xl} {
    font-size: 20px;
  }
  @media #{$sm} {
    font-size: 18px;
  }
}

.team__item-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.team__item-link {
  display: inline-block;
  + .team__item-link {
    margin-left: 10px;
  }
}

.team__item-position {
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  @media #{$xl} {
    font-size: 12px;
  }
  @media #{$sm} {
    font-size: 11px;
  }
}
