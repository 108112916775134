.tous {
  .title {
    max-width: 100px;
    line-height: 1.3;
    margin-bottom: 0;
    @media #{$md} {
      max-width: 100%;
    }
  }
  .col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media #{$md} {
      flex-direction: row;
      &:last-of-type {
        margin-bottom: 0;
        .tous__item {
          margin-bottom: 0;
        }
      }
    }
  }
}

.tous__item {
  &:first-of-type {
    margin-bottom: 20px;
  }
  @media #{$md} {
    width: 50%;
    margin-bottom: 20px;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.85;
    padding-right: 70px;
    @media #{$xl} {
      line-height: 1.6;
    }
    @media #{$lg} {
      padding-right: 15px;
    }
    @media #{$md} {
      padding-right: 38%;
    }
    @media #{$sm} {
      line-height: 1.5;
      padding-right: 15px;
    }
  }
}

.tous__item-img {
  margin-bottom: 20px;
}
