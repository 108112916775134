$num: 4;

.dropdown-menu {
  min-width: 100%;
  padding: 10px 22px;
  white-space: nowrap;
  background-color: transparent;
  box-shadow: none;

  @media #{$xl} {
    padding: 5px 12px;
  }

  @media #{$sm-min} {
    @include absolute($top: 100%, $left: 0);

    opacity: 0;
    transition:
      0.3s background-color ease,
      0.3s opacity ease,
      0.3s box-shadow ease;
  }

  &__link {
    position: relative;
    display: inline-block;
    padding: 6px 0;
    font-size: 16px;

    @media #{$xl} {
      font-size: 14px;
    }

    @media #{$sm-min} {
      &::after {
        @include absolute($bottom: 0, $left: 0);
        @include size(100%, 1px);

        background-color: $color-main;
        transform: rotateY(90deg);
        transform-origin: left;
        transition: transform .3s;
        content: '';
      }

      &:hover {
        &::after {
          transform: rotateY(0);
        }
      }
    }
  }
}

.header__nav-item {
  &--has-child {
    position: relative;
  }

  @media #{$sm-min} {
    &:hover {
      .dropdown-menu {
        opacity: 1;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
      }
    }
  }
}
