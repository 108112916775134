// Style for IE 10/11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .contact-form {
    :-ms-input-placeholder {
      color: #fff;
      opacity: 1;
    }
    .form-label {
      opacity: 0;
    }
  }

}

// Style for IE 12+, EDGE
@supports (-ms-ime-align:auto) {

  .contact-form {
    ::-ms-input-placeholder {
      color: #fff;
      opacity: 1;
    }
    .form-label {
      opacity: 0;
    }
  }

}
