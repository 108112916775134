.home {
  position: relative;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  @media #{$lg} {
    min-height: 500px;
  }
  @media #{$md} {
    min-height: 400px;
  }
  @media #{$sm} {
    min-height: inherit;
    display: flex;
    flex-direction: column-reverse;
  }
  h1 {
    font-size: 100px;
    font-weight: 900;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 50px;
    @media #{$xl} {
      font-size: 80px;
      margin-bottom: 40px;
    }
    @media #{$md} {
      font-size: 60px;
      line-height: 1.1;
      margin-bottom: 30px;
    }
    @media #{$sm} {
      font-size: 40px;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  .container {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding-left: 15px;
    @media #{$xl} {
      padding-left: 60px;
    }
    @media #{$md} {
      padding-left: 30px;
    }
    @media #{$sm} {
      position: static;
      top: 0;
      transform: none;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .row {
    overflow-x: hidden;
    margin-right: 0;
    margin-left: 0;
  }
  .col-sm-2 {
    display: flex;
    padding: 0;
    margin: 0;
    &:first-of-type {
      align-items: center;
      justify-content: center;
    }
    &:last-of-type {
      justify-content: flex-end;
    }
  }
}

.home__bg {
  width: auto;
  img {
    width: 100%;
    max-width: 100%;
  }
}

.title-dot {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: $color-second;
  border-radius: 50%;
  animation: homeDot 4s ease infinite;
  @media #{$xl} {
    width: 18px;
    height: 18px;
  }
  @media #{$md} {
    width: 16px;
    height: 16px;
  }
  @media #{$sm} {
    width: 10px;
    height: 10px;
  }
}

.home__header {

}
