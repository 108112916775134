.form {
  position: relative;
  color: #fff;
  background-color: $color-main;
  padding-top: 100px;
  padding-bottom: 100px;
  @media #{$xl} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media #{$sm} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .title {
    margin-bottom: 0;
  }
  .row {
    align-items: center;
  }
  .col-sm-2 {
    margin-bottom: 0;
  }
}

.mail-block {
  position: absolute;
  bottom: -20px;
  right: 0;
  transform: translateY(150%);
  @media #{$sm} {
    position: relative;
    transform: none;
    padding-top: 15px;
  }
}

.mail-block__text,
.mail-block__link {
  display: inline-block;
  font-size: 16px;
  @media #{$xl} {
    font-size: 14px;
  }
}

.mail-block__text {
  margin-right: 10px;
}

.mail-block__link  {
  position: relative;
  font-weight: 700;
  &::before {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
}

.contact-form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media #{$sm} {
    padding-top: 30px;
  }
  ::placeholder {
  	opacity: 0;
  }
  .btn {
    max-width: 320px;
    font-size: 16px;
    font-weight: 600;
    padding: 17px 15px;
    margin-top: 25px;
    @media #{$xl} {
      max-width: 275px;
      font-size: 14px;
    }
    @media #{$md} {
      max-width: 245px;
      font-size: 12px;
    }
  }
}

.form-row {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  &:first-of-type {
    .form-group {
      width: 36%;
      @media #{$xl} {
        width: 45%;
      }
      @media #{$sm} {
        width: 100%;
        &:first-of-type {
          margin-bottom: 45px;
        }
      }
    }
  }
  @media #{$sm} {
    flex-direction: column;
    align-items: flex-start;
  }
}

.form-group {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #777b84;
  transition: border-color .3s;
}

.form-group-error {
  border-bottom: 1px solid $color-second;
  .form-fill {
    opacity: 1;
    transform: translate(0, 150%);
  }
}

.form-label {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  font-weight: 400;
  transition: transform .3s;
  @media #{$md} {
    font-size: 14px;
  }
}

.form-control {
  z-index: 1;
  padding-bottom: 15px;
  &:focus ~ .form-label,
  &:not(:placeholder-shown) + .form-label {
    transform: scale(0.8) translateY(-36px);
    @media #{$md} {
      transform: scale(0.8) translateY(-26px);
    }
  }
  @media #{$md} {
    padding-bottom: 10px;
  }
}

.form-fill {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  color: $color-second;
  transform: translate(-10px, 150%);
  opacity: 0;
  transition: opacity .3s,
              transform .3s;
}

.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.custom-file-upload {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #777b84;
}

.file-upload-wrapper {
  position: relative;
  height: 35px;
  &::after {
    //z-index: -1;
    position: absolute;
    content: 'Додай файл резюме';
    top: 0;
    left: 0;
    display: block;
    font-size: 16px;
    font-weight: 400;
    @media #{$md} {
      font-size: 14px;
    }
  }
  &.hide {
    &::after {
      display: none;
    }
  }
}

.file-upload-input {
  width: 100%;
  //padding: 10px;
  float: left; /* IE 9 Fix */
}

.file-upload-button {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 32px;
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  border: none;
  letter-spacing: 1.2px;
  text-align: right;
  //padding-right: 16px;
  float: left; /* IE 9 Fix */
  transition: background-color .2s;
  &::before {
    z-index: -1;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 17px;
    height: 18px;
    display: block;
    background: url('/img/file-attach.svg') no-repeat center / cover;
  }
}

.form-control {
  z-index: 1;
  padding-bottom: 15px;
  &:focus ~ .form-label,
  &:not(:placeholder-shown) + .form-label {
    transform: scale(0.8) translateY(-36px);
    @media #{$md} {
      transform: scale(0.8) translateY(-26px);
    }
  }
  @media #{$md} {
    padding-bottom: 10px;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: #ссс;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
  }
}
