$side: 15px;

.container-col {
  width: 100%;
  max-width: $container;
  margin: 0 auto;
  padding-right: $side / 2;
  padding-left: $side / 2;
  @media #{$sm} {
    padding-right: 0;
    padding-left: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$side;
  margin-left: -$side;
  @media #{$sm} {
    margin-right: 0;
    margin-left: 0;
  }
}

[class*='col-'] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: $side / 2;
  padding-left: $side / 2;
  margin-bottom: $side;
  @media #{$sm} {
    padding-right: 0;
    padding-left: 0;
  }
}

// media queries (min-width: 501px)
@media #{$xs-min} {
  .col-xs-1 {
    @include col1;
  }
  .col-xs-2 {
    @include col2;
  }
  .col-xs-3 {
    @include col3;
  }
  .col-xs-4 {
    @include col4;
  }
  .col-xs-5 {
    @include col5;
  }
  .col-xs-6 {
    @include col6;
  }
  .col-xs-7 {
    @include col7;
  }
  .col-xs-8 {
    @include col8;
  }
}

// media queries (min-width: 768px)
@media #{$sm-min} {
  .col-sm-1 {
    @include col1;
  }
  .col-sm-2 {
    @include col2;
  }
  .col-sm-3 {
    @include col3;
  }
  .col-sm-4 {
    @include col4;
  }
  .col-sm-5 {
    @include col5;
  }
  .col-sm-6 {
    @include col6;
  }
  .col-sm-7 {
    @include col7;
  }
  .col-sm-8 {
    @include col8;
  }
}

// media queries (min-width: 1025px)
@media #{$md-min} {
  .col-md-1 {
    @include col1;
  }
  .col-md-2 {
    @include col2;
  }
  .col-md-3 {
    @include col3;
  }
  .col-md-4 {
    @include col4;
  }
  .col-md-5 {
    @include col5;
  }
  .col-md-6 {
    @include col6;
  }
  .col-md-7 {
    @include col7;
  }
  .col-md-8 {
    @include col8;
  }
}

// media queries (min-width: 1281px)
@media #{$lg-min} {
  .col-lg-1 {
    @include col1;
  }
  .col-lg-2 {
    @include col2;
  }
  .col-lg-3 {
    @include col3;
  }
  .col-lg-4 {
    @include col4;
  }
  .col-lg-5 {
    @include col5;
  }
  .col-lg-6 {
    @include col6;
  }
  .col-lg-7 {
    @include col7;
  }
  .col-lg-8 {
    @include col8;
  }
}

// media queries (min-width: 1601px)
@media #{$xl-min} {
  .col-xl-1 {
    @include col1;
  }
  .col-xl-2 {
    @include col2;
  }
  .col-xl-3 {
    @include col3;
  }
  .col-xl-4 {
    @include col4;
  }
  .col-xl-5 {
    @include col5;
  }
  .col-xl-6 {
    @include col6;
  }
  .col-xl-7 {
    @include col7;
  }
  .col-xl-8 {
    @include col8;
  }
}
