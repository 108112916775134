.jobs {

}

.jobs__block {
  padding-bottom: 70px;
  @media #{$xl} {
    padding-bottom: 40px;
  }
  @media #{$md} {
    padding-bottom: 30px;
  }
  @media #{$sm} {
    padding-bottom: 20px;
  }
}

.jobs__item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.025em;
  padding-top: 40px;
  padding-bottom: 30px;
  border-top: 1px solid #d9d9d9;
  overflow: hidden;
  transition: transform .5s,
              opacity .2s,
              border-color .2s;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    //right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-color: #fff4ee;
    opacity: 0;
    transition: transform .5s,
                opacity .2s;
  }
  &:last-of-type {
    border-bottom: 1px solid #d9d9d9;
    &:hover {
      border-color: transparent;
    }
  }
  &:hover {
    border-color: transparent;
    &::before {
      transform: translateX(0);
      opacity: 1;
    }
    + .jobs__item {
      border-color: transparent;
    }
    .jobs__item-header {
      transform: translateX(40px);
      color: $color-second;
      @media #{$sm} {
        transform: translateX(20px);
      }
    }
    .jobs__item-city {
      transform: translateX(-40px);
      color: $color-second;
      &::after {
        border-color: $color-second;
      }
      @media #{$sm} {
        transform: translateX(-20px);
      }
    }
  }
  @media #{$xl} {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media #{$sm} {
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

.jobs__item-header,
.jobs__item-city {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  z-index: 1;
  transition: transform .5s;
  transform: translateX(0);
}

.jobs__item-header {
  padding-right: 15px;
}

.jobs__item-position {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-bottom: 18px;
  transition: transform .5s,
              color .4s;
  @media #{$xl} {
    font-size: 20px;
    margin-bottom: 15px;
  }
  @media #{$sm} {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.jobs__item-level,
.jobs__item-city {
  font-size: 21px;
  @media #{$xl} {
    font-size: 19px;
  }
  @media #{$sm} {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
}

.jobs__item-city {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 40px;
  &::after {
    position: absolute;
    content: '';
    top: 3px;
    right: 5px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    display: block;
    border: 3px solid $color-main;
    border-left: 0;
    border-bottom: 0;
    transition: border-color .2s;
    @media #{$sm} {
      top: 0;
    }
  }
  @media #{$sm} {
    padding-right: 25px;
  }
}
