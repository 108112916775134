.company {

}

.company__row {
  width: 100%;
  display: flex;
  @media #{$sm} {
    flex-direction: column;
  }
}

.company__col {
  &:first-of-type {
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 15px;
    @media #{$sm} {
      flex: 0 0 auto;
      max-width: 100%;
      padding-right: 0;
    }
  }
  &:last-of-type {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 145px;
    @media #{$xl} {
      padding-left: 80px;
    }
    @media #{$md} {
      padding-left: 40px;
    }
    @media #{$sm} {
      flex: 0 0 auto;
      max-width: 100%;
      padding-left: 0;
    }
  }
}

.company__box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  // &--mark {
  //   position: relative;
  //   justify-content: center;
  //   color: $color-second;
  //   background-color: #fff4ee;
  //   padding: 20px;
  //   &::before {
  //     z-index: 1;
  //     position: absolute;
  //     content: '';
  //     top: 50%;
  //     left: 0;
  //     transform: translateY(-50%);
  //     width: 3px;
  //     height: 75%;
  //     display: block;
  //     background-color: $color-second;
  //   }
  //   @media #{$xl} {
  //     padding: 15px;
  //   }
  // }
  @media #{$sm} {
    padding: 20px;
    margin-bottom: 0;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      justify-content: flex-end;
    }
  }
}

.company__box-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.667;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  padding-left: 20px;
  @media #{$xl} {
    font-size: 15px;
  }
  @media #{$md} {
    font-size: 12px;
  }
}
