.header {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    display: block;
    background-color: #fff;
    box-shadow: transparent;
    transition: height .3s,
                box-shadow .2s ease .3s;
  }
  @media #{$lg} {
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.11);
    &::before {
      display: none;
    }
  }
  @media #{$sm} {
    height: 50px;
    padding-left: 15px;
  }
  &.is-active {
    &::before {
      height: 100%;
      box-shadow: 0 0 10px 0 rgba(0,0,0,.11);
    }
  }
}

.header__logo-img {
  cursor: pointer;
  max-width: 230px;
  margin-right: 40px;
  margin-left: 15px;
  @media #{$xl} {
    max-width: 200px;
    margin-right: 20px;
  }
  @media #{$sm} {
    max-width: 180px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 25px;
  }
}

.header__nav {
  display: flex;
  align-items: center;
  @media #{$sm} {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 50px);
    @include centerFlex;
    background-color: #fff;
    padding-bottom: 30px;
    transform: translateX(-100%);
    //transition: transform .5s;
    &.is-active {
      transition: transform .5s;
      transform: translateX(0);
      .header__nav-item {
        transform: translateX(0);
        @for $i from 1 through 10 {
          &:nth-of-type(#{$i}) {
            transition-delay: .1s * $i;
          }
        }
      }
    }
    &.transition {
      transition: transform .5s;
    }
  }
}

.header__nav-list {
  display: flex;
  @media #{$sm} {
    width: 100%;
    display: block;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.header__nav-item {
  padding: 10px 22px;
  @media #{$xl} {
    padding: 5px 12px;
  }
  @media #{$sm} {
    line-height: 2;
    padding: 10px 12px;
    transform: translateX(100%);
    transition: transform .3s;
  }
}

.header__nav-item:not(:last-of-type) {
  @media #{$sm} {
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 50px;
      height: 1px;
      display: block;
      background-color: #eee;
    }
  }
}

.header__nav-link,
.nav-job-link {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  &::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $color-main;
    transform: rotateY(90deg);
    transform-origin: left;
    transition: transform .3s;
    @media #{$md} {
      display: none;
    }
  }
  &:hover {
    &::after {
      transform: rotateY(0);
    }
  }
  @media #{$xl} {
    font-size: 14px;
  }
  @media #{$sm} {
    font-size: 18px;
  }
  &.is-active {
    color: $color-second !important;
    &::after {
      background-color: $color-second;
    }
  }
}

.burger-menu {
  cursor: pointer;
  z-index: 1;
  width: 34px;
  height: 34px;
  @include centerFlex;
  display: none;
  @media #{$sm} {
    display: flex;
  }
}

.burger-menu__item {
  width: 30px;
  height: 3px;
  background-color: $color-main;
  border-radius: 2px;
  transition: transform .3s,
              background-color .3s;
  + .burger-menu__item {
    margin-top: 5px;
  }
  &.is-active {
    &:nth-of-type(1) {
      transform: translateY(8px) rotate(45deg);
    }
    &:nth-of-type(2) {
      transition: none;
      background-color: transparent;
    }
    &:nth-of-type(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
