// reset style
@import 'reset';

// base
@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/base';
@import 'base/animations';

// layout
@import 'layout/header';
@import 'layout/footer';

// libs
@import 'libs/aos';
@import 'libs/slick';

// common
@import 'common/common';
@import 'common/column';

// components
@import 'components/fly-box';
@import 'components/modal';
@import 'components/modal-event';
@import 'components/dropdown';

// section
@import 'section/home';
@import 'section/about-company';
@import 'section/you-to-us';
@import 'section/about-us';
@import 'section/jobs-link';
@import 'section/customers';
@import 'section/customer-reviews';
@import 'section/for-you';
@import 'section/social-activity';
@import 'section/our-team';
@import 'section/jobs';
@import 'section/form';
@import 'section/job-desc';

// browsers
@import 'browsers/ie';
// @import 'browsers/firefox';
// @import 'browsers/safari';
// @import 'browsers/chrome';
