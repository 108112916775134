.activity {
  .list {
    margin-top: 20px;
  }
  .col-sm-2 {
    &:first-of-type {
      position: relative;
      @media #{$sm} {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &:last-of-type {

    }
  }
  .row {
    @media #{$sm} {
      flex-direction: column-reverse;
    }
  }
}

.activity__box {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  width: 56%;
  color: $color-second;
  background-color: #fff4ee;
  padding: 50px 60px;
  border-radius: 5px;
  @media #{$xl} {
    padding: 35px 40px;
  }
  @media #{$md} {
    padding: 35px 15px;
  }
  @media #{$sm} {
    position: relative;
    top: 0;
    right: 0;
    transform: none;
    width: 100%;
    margin-top: 40px;
  }
  &::after {
    z-index: 1;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    transform: translate(50%,-50%);
    width: 65px;
    height: 64px;
    display: block;
    background: url('/img/activity/activity-ic.svg') no-repeat center / cover;
    @media #{$md} {
      width: 55px;
      height: 54px;
    }
    @media #{$sm} {
      width: 45px;
      height: 44px;
    }
  }
  .text {
    color: $color-second;
  }
}

.activity__img {
  width: 58%;
  height: 100%;
  background: url('/img/activity/activity-img.jpg') no-repeat center / cover;
  border-radius: 5px;
  overflow: hidden;
  @media #{$md} {
    width: 80%;
  }
  @media #{$sm} {
    width: 100%;
    height: 200px;
  }
}
