.link {
  background-color: $color-main;
  @media #{$sm} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .title {
    margin-bottom: 50px;
    @media #{$xl} {
      margin-bottom: 30px;
    }
    @media #{$md} {
      margin-bottom: 20px;
    }
  }
  .title-thin {
    color: #fff;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
